"use client";

/**
 * Thid-party libraries.
 */
import { Spin, SpinProps } from "antd";

type LoadingProps = {
  /**
   * Whether the loading icon is spinning.
   */
  spinning?: SpinProps["spinning"];
  /**
   * Size of the loading icon.
   */
  size?: SpinProps["size"];
  /**
   * Text displayed under the spinning icon.
   */
  tip?: SpinProps["tip"];
};

/**
 *
 * Global default loading for pages, should be used to show a loading icon
 *  before getting into a page.
 *
 * @param param
 *
 * @returns A spinning loading icon.
 */
export default function Loading({
  tip,
  spinning = true,
  size = "default",
}: LoadingProps) {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <Spin spinning={spinning} size={size} tip={tip}>
        <div className="content" />
      </Spin>
    </div>
  );
}
