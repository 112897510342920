import dotenv from "dotenv";

dotenv.config();

// =============================================================================
// Application
// =============================================================================

/**
 * Port number for the local server.
 */
export const PLAYWRIGHT_APPLICATION_PORT = process.env.PORT || 3000;

/**
 * Base URL of the Archus Cloud Contact Center application.
 */
export const PLAYWRIGHT_APPLICATION_BASE_URL = `http://localhost:${PLAYWRIGHT_APPLICATION_PORT}`;

/**
 * Base URL of the Skype web application.
 */
export const PLAYWRIGHT_SKYPE_BASE_URL = "https://web.skype.com";

// =============================================================================
// Authentication
// =============================================================================

export const PLAYWRIGHT_AUTH0_ISSUER_BASE_URL =
  process.env.AUTH0_ISSUER_BASE_URL!;

// =============================================================================
// Credentials
// =============================================================================

/**
 * Archus Cloud Contact Center user email.
 *
 * This will be used to login to the Archus Cloud Contact Center.
 */
export const PLAYWRIGHT_USER_EMAIL = process.env.PLAYWRIGHT_USER_EMAIL!;
/**
 * Archus Cloud Contact Center user password.
 *
 * This will be used to login to the Archus Cloud Contact Center.
 */
export const PLAYWRIGHT_USER_PASSWORD = process.env.PLAYWRIGHT_USER_PASSWORD!;

/**
 * Skype user email.
 *
 * This will be used to login to Skype.
 */
export const PLAYWRIGHT_SKYPE_USER_EMAIL =
  process.env.PLAYWRIGHT_SKYPE_USER_EMAIL!;
/**
 * Skype user password.
 *
 * This will be used to login to Skype.
 */
export const PLAYWRIGHT_SKYPE_USER_PASSWORD =
  process.env.PLAYWRIGHT_SKYPE_USER_PASSWORD!;

// =============================================================================
// Phone Numbers
// =============================================================================
/**
 * The phone number of the application that is used to make calls.
 */
export const PLAYWRIGHT_APPLICATION_PHONE_NUMBER =
  process.env.NEXT_PUBLIC_TWILIO_PHONE_NUMBER!;
/**
 * The phone number of the Skype account that is used to make calls.
 */
export const PLAYWRIGHT_SKYPE_PHONE_NUMBER =
  process.env.PLAYWRIGHT_SKYPE_PHONE_NUMBER!;

// =============================================================================
// Playwright Storage
// =============================================================================

/**
 * The JSON path of to use to store the Skype brownser context.
 */
export const PLAYWRIGHT_SKYPE_CONTEXT_STORAGE =
  "playwright/storage/playwright-skype-context.json";
