"use client";

/**
 * Third-party libraries.
 */
import Icon, {
  CloseOutlined as CloseOutlinedIcon,
  SettingOutlined as SettingOutlinedIcon,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Layout,
  MenuProps,
  Select,
  Tag,
  Tooltip,
} from "antd";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { UserAvatar } from "@/components/client/avatar";
import { useCommunicationLogContext } from "@/components/client/communication-log";
import { useApplicationContext } from "@/components/client/context";
import { Dialer } from "@/components/client/dialer";
import {
  useBusinessHoursEventSubscription,
  useBusinessHoursQuery,
  useCallCreateMutation,
  UserAvailabilityStatus,
  useSystemPreferenceQuery,
} from "@/components/client/graphql";
import { useInterval } from "@/components/client/hooks/use-interval";
import { ThePiqueLabLogo } from "@/components/client/images";
import DialPad from "@/components/client/images/dial-pad.svg";
import { Loading } from "@/components/client/loading";
import { useMessageContext } from "@/components/client/message";
import { PermissionRequired } from "@/components/client/permission";
import { useTwilioContext } from "@/components/client/twilio";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { BusinessHourUtility } from "@/components/common/business-hour";
import { ApiRoute } from "@/components/common/route";
import { SystemPreferenceKey } from "@/components/common/system-preference";
import { BusinessStatus } from "@/components/common/system-preference/types";
import { StringUtility } from "@/components/common/utilities";
import { PlayWrightTestId } from "@/tests/constants";

/**
 * Default protected page layout header properties.
 */
type DefaultProtectedPageLayoutHeaderProps = {};

const HEADER_ICON_CLASSES = "!text-2xl !text-tpl-navy";

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutHeader({}: DefaultProtectedPageLayoutHeaderProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const router = useRouter();
  /**
   * Logged on user details.
   */
  const { user, error, fetching } = useAuthenticationContext();

  /**
   * Application Context.
   */
  const {
    setShowDialer,
    setShowSettings,
    setUserAvailabilityStatus,
    showDialer,
    showSettings,
    updatingUserAvailabilityStatus,
    userAvailabilityStatus,
  } = useApplicationContext();

  const { hasActiveCommunicationLog } = useCommunicationLogContext();

  const message = useMessageContext();

  // ===========================================================================
  // ===========================================================================
  // Queries
  // ===========================================================================
  // ===========================================================================

  const { data: businessHoursSettingsData } = useSystemPreferenceQuery({
    variables: { filter: { key: SystemPreferenceKey.BUSINESS_STATUS } },
  });

  const businessHoursQuery = useBusinessHoursQuery();

  /**
   * This automatically updates the businessHours query, when a new event is received.
   * Not sure how it works though.
   *
   * TODO: Please investigate and indicate here how it works.
   */
  useBusinessHoursEventSubscription();

  // ===========================================================================
  // ===========================================================================
  // Mutations
  // ===========================================================================
  // ===========================================================================

  const [createCall, { loading: creatingCall }] = useCallCreateMutation();

  /**
   * Twilio Context.
   */
  const { device, deviceRegistering } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * User initials.
   */
  const [userInitials, setUserAvatarInitials] = useState<string | null>();

  /**
   * User avatar menu items.
   */
  const [userAvatarMenuItems, setUserAvatarMenuItems] =
    useState<MenuProps["items"]>();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  /**
   * Indicates that whether the business status is open, close, or determined
   * based on schedule..
   */
  const businessStatus = useMemo<
    BusinessStatus.OPEN | BusinessStatus.CLOSE
  >(() => {
    const businessStatus = businessHoursSettingsData?.systemPreference
      ?.value as BusinessStatus;
    if (businessStatus !== BusinessStatus.SCHEDULE) {
      return businessStatus;
    }

    const businessHours = businessHoursQuery.data?.businessHours || [];

    /** Evaluate if the current time is within business hour range. */
    const isBusinessOpen = BusinessHourUtility.isBusinessOpen({
      businessHours,
    });

    return isBusinessOpen ? BusinessStatus.OPEN : BusinessStatus.CLOSE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessHoursSettingsData?.systemPreference?.value,
    businessHoursQuery.data?.businessHours,
    /** Intentionally left this here so that the the business hour status will be updated real-time. */
    currentDateTime,
  ]);

  useInterval(() => setCurrentDateTime(new Date()), 1000);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const onDialerCall = useCallback(
    async ({
      phoneNumber,
    }: {
      /**
       * Phone number to call.
       */
      phoneNumber: string;
    }) => {
      await createCall({
        variables: {
          input: {
            to: phoneNumber,
          },
        },
      })
        .then((data) => {
          // Hide the dialer.
          setShowDialer(false);
        })
        .catch((err) => {
          message.error(err.message);
        });
    },
    [createCall, message, setShowDialer],
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the user avatar initials and menu items.
   */
  useLayoutEffect(() => {
    // Get the user initials.
    setUserAvatarInitials(
      StringUtility.getInitials({ input: user?.name ?? "", maxLength: 2 }),
    );

    /**
     * Create the drop down menu for the user avatar.
     */
    setUserAvatarMenuItems([
      {
        key: "1",
        disabled: true,
        label: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            {user?.name}
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link href={ApiRoute.AUTHENTICATION_LOGOUT} prefetch={false}>
            Logout
          </Link>
        ),
      },
    ]);
  }, [router, user]);

  if (fetching) {
    return <Loading size="large" />;
  }

  if (error) return <div>{error.message}</div>;

  if (!user) {
    router.push(ApiRoute.AUTHENTICATION_LOGIN);

    return <Loading size="large" />;
  }

  if (user && !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS)) {
    router.push("/401");

    return <Loading size="large" />;
  }

  const businessStatusTooltipTitle = (() => {
    switch (businessStatus) {
      case BusinessStatus.OPEN:
        return "Accepting client calls.";
      case BusinessStatus.CLOSE:
        return "Not accepting client calls.";
      default:
        return "Not accepting client calls.";
    }
  })();

  const businessStatusChipColor = (() => {
    switch (businessStatus) {
      case BusinessStatus.OPEN:
        return "var(--semantic-green)";
      case BusinessStatus.CLOSE:
        return "var(--semantic-red)";
      default:
        return "Not accepting client calls.";
    }
  })();

  return (
    <Layout.Header
      className="flex justify-between"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <ThePiqueLabLogo />
      <div className="flex items-center gap-2">
        <Tooltip title={businessStatusTooltipTitle}>
          <Tag
            className="cursor-default"
            id="businessStatus"
            color={businessStatusChipColor}
          >
            {businessStatus === BusinessStatus.OPEN ? "Open" : "Closed"}
          </Tag>
        </Tooltip>
        <Tooltip
          title={
            hasActiveCommunicationLog || !device || deviceRegistering
              ? "Disabled During Active Logs"
              : "Dial Pad"
          }
        >
          <Button
            data-testid={PlayWrightTestId.Header.DIALER_BUTTON}
            disabled={hasActiveCommunicationLog || !device || deviceRegistering}
            icon={<Icon className={HEADER_ICON_CLASSES} component={DialPad} />}
            onClick={() => setShowDialer(!showDialer)}
            type="text"
          />
        </Tooltip>
        <PermissionRequired
          requiredPermissions={[Auth0Permission.SETTINGS_VIEW]}
        >
          <Tooltip title="Settings">
            <Button
              type="text"
              icon={
                <SettingOutlinedIcon className="!text-2xl !text-tpl-navy" />
              }
              onClick={() => setShowSettings(!showSettings)}
            />
          </Tooltip>
        </PermissionRequired>
        <Select
          className="user-availability-status-selector"
          data-testid={
            PlayWrightTestId.Header.USER_AVAILABILITY_STATUS_SELECTOR
          }
          disabled={
            !device ||
            updatingUserAvailabilityStatus ||
            deviceRegistering ||
            userAvailabilityStatus === UserAvailabilityStatus.OnACall ||
            userAvailabilityStatus === UserAvailabilityStatus.Ringing ||
            userAvailabilityStatus === UserAvailabilityStatus.WrappingUp
          }
          loading={updatingUserAvailabilityStatus || deviceRegistering}
          options={[
            {
              label: "Available",
              value: UserAvailabilityStatus.Available,
            },
            {
              label: "Break",
              value: UserAvailabilityStatus.Break,
            },
            {
              label: "Busy",
              value: UserAvailabilityStatus.Busy,
            },
            {
              label: "Offline",
              value: UserAvailabilityStatus.Offline,
            },
            {
              disabled: true,
              label: "Ringing",
              value: UserAvailabilityStatus.Ringing,
            },
            {
              disabled: true,
              label: "On A Call",
              value: UserAvailabilityStatus.OnACall,
            },
            {
              disabled: true,
              label: "Wrapping Up",
              value: UserAvailabilityStatus.WrappingUp,
            },
          ]}
          onChange={(value) => {
            setUserAvailabilityStatus({ status: value });
          }}
          style={{
            minWidth: "126px",
          }}
          value={userAvailabilityStatus}
        />
        <Dropdown
          menu={{ items: userAvatarMenuItems }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div>
            <UserAvatar
              status={userAvailabilityStatus}
              initials={userInitials ?? undefined}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </Dropdown>
        <Drawer
          closable={false}
          // mask={false}
          onClose={() => setShowDialer(false)}
          open={showDialer}
          styles={{
            body: {
              padding: 0,
            },
          }}
          title={
            <span className="flex w-full items-center justify-between">
              Dialer
              <Tooltip title="Close">
                <Button
                  icon={<CloseOutlinedIcon className="!text-tpl-navy" />}
                  onClick={async () => setShowDialer(false)}
                  shape="circle"
                  type="text"
                />
              </Tooltip>
            </span>
          }
        >
          <Dialer
            disabled={hasActiveCommunicationLog || !device || deviceRegistering}
            loading={creatingCall}
            onDial={onDialerCall}
          />
        </Drawer>
      </div>
    </Layout.Header>
  );
}
