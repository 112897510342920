import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { Auth0User } from "@/components/common/auth0/types";

/**
 * Checks if the user has the required permissions to access the resource.
 *
 * @returns `true` if the user has one of the required permissions, `false` otherwise.
 *
 * @example
 * ```typescript
 * // Returns `true`, because the user has the "user:read" permission
 * isAuthorized({
 *  userPermissions: ["user:read"],
 *  // This means that the user needs to either have
 *  // 1. the "user:read" permission OR
 *  // 2. the "user:create" permission.
 *  requiredPermissions: ["user:read", "user:create"]
 * });
 *
 * // Returns `false`, because the user does not have the "user:admin" permission and
 * // does not have both the "user:create" and "user:update" permission
 * isAuthorized({
 *  userPermissions: ["user:read", "user:create"],
 *  // This means that the user needs to either have
 *  // 1. the "user:admin" permission OR
 *  // 2. both the "user:create" and "user:update" permission.
 *  requiredPermissions: ["user:admin", ["user:create", "user:update"]]
 * });
 * ```
 */
export const isAuthorized = (options: {
  /** The list of permissions the user has. */
  userPermissions: Auth0User["permissions"];
  /**
   * The list of permissions required to access the resource.
   * The user must match one of the required permissions.
   *
   * A required permission can be:
   * - string - the only permission required.
   * - array - all the permissions required.
   */
  requiredPermissions: (Auth0Permission | Auth0Permission[])[];
}) => {
  const { userPermissions, requiredPermissions } = options;

  // Resource did not require any permissions.
  if (requiredPermissions.length === 0) {
    return true;
  }

  // User permission must have at least one of the required permissions.
  return requiredPermissions.some((requiredPermission) => {
    /**
     * Required permission is an array of permissions. This means that
     * all permissions are required to be present in the token to be granted
     * access to the resource.
     */
    if (Array.isArray(requiredPermission)) {
      return requiredPermission.every((permission) =>
        userPermissions.includes(permission),
      );
    }

    /**
     * Permission is a string. Only one permission from the 1st level array is
     * required.
     */
    return userPermissions.includes(requiredPermission);
  });
};
