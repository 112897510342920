/**
 * Types of a phone number.
 */
export enum PhoneNumberType {
  /**
   * A landline phone number.
   * This type of phone number only supports Voice.
   */
  LANDLINE = "LANDLINE",
  /**
   * A mobile phone number.
   * This type of phone number can supports Voice and SMS.
   */
  MOBILE = "MOBILE",
}
