import {
  COUNTRY,
  COUNTRY_CODE,
  CountryDetails,
} from "@/components/client/country/types";
import { PhoneNumberType } from "@/components/common/phone-number/types";

type ValidatedPhoneNumber = {
  /**
   * The country details of the phone number matched.
   */
  country: CountryDetails;
  /**
   * Phone number without the country code.
   *
   * @example
   * 91234567
   */
  phoneNumber: string;
  /**
   * Type of phone number.
   *
   * Right now only Singapore phone numbers are supported by this property.
   * Validating a non Singapore phone number will return undefined.
   *
   * @returns Returns the phone number type if validated, otherwise undefined.
   */
  type?: PhoneNumberType;
};

/**
 * Checks if the given value is valid as phone number.
 *
 * @see https://www.iban.com/dialing-codes
 *
 * @example
 * {
 *  country: {
 *    code: 65,
 *    flag: "🇸🇬",
 *    name: "Singapore",
 *    phoneNumber: {
 *      length: {
 *        min: 7,
 *        max: 7,
 *    },
 *    startsWith: [6, 8, 9],
 *  },
 *  phoneNumber: 61234567
 *  type: MOBILE
 * }
 *
 * @returns Returns the phone number details if validated, otherwise null.
 */
export function validate({
  phoneNumber,
}: {
  /**
   * Phone number to validate.
   */
  phoneNumber: string;
}): ValidatedPhoneNumber | null {
  for (const countryCodeKey of Object.keys(COUNTRY_CODE)) {
    /**
     * Number which has special characters removed.
     *
     * @example
     * 6561234567
     */
    const cleanedNumber = phoneNumber.replace(/\s/g, "").replace(/[^\d]/g, "");

    /**
     * The country details of the phone number matched.
     */
    const countryCodeDetails =
      COUNTRY_CODE[countryCodeKey as keyof typeof COUNTRY_CODE];

    // =========================================================================
    // START: For development purposes only.
    // =========================================================================
    /**
     * Overrides the phone number to be marked as a mobile number.
     * Skype Dev1 Development phone number.
     */
    if (cleanedNumber === "17857184924") {
      return {
        country: COUNTRY.UnitedStates,
        phoneNumber: "7857184924",
        type: PhoneNumberType.MOBILE,
      };
    }
    // =========================================================================
    // END: For development purposes only.
    // =========================================================================

    /**
     * Regular expression pattern for finding the country, and the phone number
     * of a given international formatted phone number.
     *
     * @example
     * /^(?<countryCode>65)(?<phoneNumber>(6|8|9)\d{7,7}))$/
     */
    const regexPattern = countryCodeDetails.phoneNumber.startsWith?.length
      ? `^(?<countryCode>${
          countryCodeDetails.code
        })(?<phoneNumber>(${countryCodeDetails.phoneNumber.startsWith?.join(
          "|"
        )})\\d{${countryCodeDetails.phoneNumber.length.min},${
          countryCodeDetails.phoneNumber.length.max
        }})$`
      : `^(?<countryCode>${countryCodeDetails.code})(?<phoneNumber>\\d{${countryCodeDetails.phoneNumber.length.min},${countryCodeDetails.phoneNumber.length.max}})$`;

    const regex = new RegExp(regexPattern, "g");

    const match = regex.exec(cleanedNumber);

    /**
     * No matching country code and phone number found.
     */
    if (match?.groups && match.groups.countryCode && match.groups.phoneNumber) {
      let phoneNumberType: ValidatedPhoneNumber["type"] = undefined;

      /**
       * Currently, only Singapore phone number is supported.
       * This can be extended to support other countries.
       */
      switch (countryCodeDetails.code) {
        case COUNTRY.Singapore.code:
          // Singapore landline numbers start with 6.
          if (match.groups.phoneNumber.startsWith("6")) {
            phoneNumberType = PhoneNumberType.LANDLINE;
            break;
          }
          // Singapore mobile numbers start with 8 or 9.
          else if (
            match.groups.phoneNumber.startsWith("8") ||
            match.groups.phoneNumber.startsWith("9")
          ) {
            phoneNumberType = PhoneNumberType.MOBILE;
            break;
          }
        default:
          phoneNumberType = undefined;
      }

      return {
        /**
         * The country details of the phone number matched.
         */
        country: countryCodeDetails,
        /**
         * Phone number without the country code.
         *
         * @example
         * 91234567
         */
        phoneNumber: match.groups.phoneNumber,
        /**
         * Type of phone number.
         */
        type: phoneNumberType,
      };
    }
  }

  return null;
}
