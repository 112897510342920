import { useEffect } from "react";

/**
 * Custom hook that creates an interval that invokes a callback function at a specified delay using the [`setInterval API`](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setInterval).
 * @param {() => void} fn - The function to be invoked at each interval.
 * @param {number | null} delay - The time, in milliseconds, between each invocation of the callback. Use `null` to clear the interval.
 * @example
 * ```tsx
 * const handleInterval = () => {
 *   // Code to be executed at each interval
 * };
 * useInterval(handleInterval, 1000);
 * ```
 */
export function useInterval(fn: () => void, delay: number | null) {
  useEffect(() => {
    /** Don't schedule if no delay is specified. */
    if (delay == null) return;

    const id = setInterval(fn, delay);
    return () => clearInterval(id);
  }, [fn, delay]);
}
